export class Marquee {
    constructor(el) {
        // .menu element
        this.DOM = { el: el };
        // the menu items
        this.DOM.marqueeInner = this.DOM.el.children[0];
        this.DOM.marqueeItems = this.DOM.marqueeInner.children[0];
        this.screenWidth = globalThis.outerWidth;
        this.numberOfItems = Math.ceil(
            this.screenWidth / this.DOM.marqueeItems.clientWidth
        );
        this.itemWidth = this.DOM.marqueeItems.clientWidth;
        this.setupMarquee();
        let onResizeEnd;
        window.addEventListener('resize', () => {
            if (onResizeEnd) {
                clearTimeout(onResizeEnd);
            }
            onResizeEnd = setTimeout(() => this.reinitOnResize(), 600);
        });
    }
    setupMarquee() {
        const parent = this.DOM.el;
        const attrs = parent.attributes;
        const attrsObj = {};
        for (const key in attrs) {
            const element = attrs[key];
            if (typeof element === 'object') {
                if (element.name.startsWith('fr-')) {
                    attrsObj[element.name] = element.value;
                }
            }
        }
        this.speed = attrsObj['fr-marquee-speed'];
        this.direction =
            attrsObj['fr-marquee-direction'] === 'r-l' ? 'normal' : 'reverse';
        this.paused = attrsObj['fr-marquee-pause-on-hover'];

        this.buildItems();
        this.animate();
    }

    animate() {
        const animator = this.DOM.marqueeInner.animate(
            [
                // keyframes
                { transform: `translate3d(-${this.itemWidth}px, 0, 0)` }
            ],
            {
                // timing options
                duration: parseInt(this.speed),
                iterations: Infinity,
                direction: this.direction
            }
        );
        console.log('this.paused', typeof this.paused);
        if (this.paused === 'true') {
            const onMouseEnter = (ev) => animator.pause();
            const onMouseLeave = (ev) => animator.play();
            this.DOM.el.addEventListener('mouseenter', onMouseEnter);
            this.DOM.el.addEventListener('mouseleave', onMouseLeave);
        }
    }

    buildItems() {
        const items = [];

        this.DOM.marqueeItems.style.width = this.itemWidth + 'px';

        for (let i = this.numberOfItems; i--; )
            items.push(this.DOM.marqueeItems.cloneNode(true));

        items.forEach((i, idx) => {
            this.DOM.marqueeInner.appendChild(i);
        });
    }
    reinitOnResize() {
        for (const [index, val] of this.DOM.el
            .querySelectorAll('.marquee-section')
            .entries()) {
            if (index !== 0) val.remove();
        }
        this.screenWidth = globalThis.outerWidth;
        this.numberOfItems = Math.ceil(
            this.screenWidth / this.DOM.marqueeItems.clientWidth
        );
        this.itemWidth = this.DOM.marqueeItems.clientWidth;
        this.buildItems();
    }
}
